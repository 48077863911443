<template>
  <article>
    <Hero heading="Thank you" :subheading="true">
      <template #subheading>
        <p>Your information has been received.</p>
      </template>
    </Hero>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'thank-you',
  metaInfo: {
    title: 'Electrify America: U.S. EV public charging network | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'The leader in electric vehicle (EV) charging. Electrify America offers the most public fast charging stations in the U.S., plus commercial products.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  components: { Hero },
};
</script>
